<template>
<nav class="nav">
  <div class="logoContainer">
    <img :src="require('@/assets/white-logo.png')" alt="">
    <h3 style="margin-left: 30px;">{{navTitle}}</h3>
  </div>
  <div style="white-space: nowrap">
      <van-button size="small" @click="$$i18n.locale = 'cn'" round type="info" v-if="lang == 'en'">CN</van-button>
      <van-button size="small" @click="$$i18n.locale = 'en'" round type="info" v-else>EN</van-button>
      <van-button style="margin-left: 20px" @click="show = true" icon="wap-nav" size="small" />
  </div>
  
  <van-popup position="left" :style="{ width: '50%', maxWidth: '300px', height: '100%' }" v-model="show">
    <div class="navBox">
      <div class="navItem" style="background-color: #358bff;border: none">
        <img :src="require('@/assets/white-logo.png')" alt="">
      </div>
      <router-link v-if="haveLogin" class="navItem" to="/admin/index">首页</router-link>
       <div v-if="haveLogin" class="navItem" @click="logout" >{{t('Sign Out')}}</div>
    </div>
  </van-popup>
</nav>
</template>

<script>
import Vue from 'vue';
export default {
  props: {
    navTitle: {
      type: String,
      default: "DEEPER NETWORK"
    }
  },
  data() {
    return {
      show: false,
      haveLogin: false,
    }
  },
  computed: {
    lang() {
      return Vue.prototype.$$i18n.locale
    }
  },
  mounted() {
    this.checkLogin()
    
  },
  methods: {
    t(word) {
      return this.$t(`navbar.${word}`)
    },
    checkLogin() {
      const token = window.localStorage.getItem('adminToken');
      if (!token) {
        this.haveLogin = false
        return 
      }
      this.haveLogin = true
    },
    logout() {
      window.localStorage.clear()
      window.location.href= '/admin/login'
    }
  }
}
</script>

<style lang="less" scoped>
.nav {
  background-color: #358bff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 0 20px;
  height: 60px;
  .logoContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;

  }
}
.navBox {
  display: flex;
  flex-direction: column;
  .navItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    color: #333;
    border-top: 1px solid #ddd;
  }
}
</style>8